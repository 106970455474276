import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Work a weakness for :20-:30`}</p>
    <p>{`then,`}</p>
    <p>{`25:00 AMRAP of:`}</p>
    <p>{`200M Run`}</p>
    <p>{`10-CTB Pullups`}</p>
    <p>{`15-KBS (53/35)(rx+ 70/53)`}</p>
    <p>{`20-Squats`}</p>
    <p><em parentName="p">{`*`}{`compare to 8/29/19`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      